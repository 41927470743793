import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ContactForm } from "./ContactForm"
import { Diptych } from "../components/Diptych"
import { useLanguage } from "../utils/useLanguage"

const i18n = {
  es: {
    title: "Contacta conmigo",
  },
  en: {
    title: "Contact me",
  },
}

export const ContactFormDiptych = () => {
  const language = useLanguage()
  const data = useStaticQuery(query)
  return (
    <Diptych
      imageFluid={data.postImage.childImageSharp.fluid}
      imageHeightRatio={95}
      title={i18n[language].title}
      content={<ContactForm />}
    />
  )
}

export const query = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid {
        base64
        tracedSVG
        aspectRatio
        srcWebp
        srcSetWebp
        originalName
      }
    }
  }

  query {
    postImage: file(relativePath: { eq: "stories/post.jpg" }) {
      ...FluidImage
    }
  }
`
