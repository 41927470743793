import styled from "styled-components"
import { colors, measure } from "../styles"

export const Button = styled.button`
  background-color: ${colors.backgroundStrong};
  color: ${colors.white};
  height: ${measure.measure48};
  border: none;
  border-radius: 5px;
  min-width: ${measure.measure256};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }

  &:disabled {
    cursor: auto;
    opacity: 0.4;
  }
`
