import React from "react"
import styled from "styled-components"
import { colors, fontSizes, measure } from "../styles"
import { HiCheckCircle, HiXCircle } from "react-icons/hi"

type Props = {
  className?: string
  children: string
  variant: "success" | "error"
}

const SuccessIcon = styled(HiCheckCircle)`
  font-size: 24px;
  color: ${colors.emerald500};
`
const ErrorIcon = styled(HiXCircle)`
  font-size: 24px;
  color: ${colors.red500};
`

const theme = {
  success: {
    background: colors.emerald50,
    border: colors.emerald200,
    text: colors.emerald700,
    icon: <SuccessIcon />,
  },
  error: {
    background: colors.red50,
    border: colors.red200,
    text: colors.red700,
    icon: <ErrorIcon />,
  },
}

const Root = styled.div<Pick<Props, "variant">>`
  background-color: ${({ variant }) => theme[variant].background};
  border: 1px solid ${({ variant }) => theme[variant].border};
  border-radius: 5px;
  padding: ${measure.measure16};

  display: grid;
  grid-auto-flow: column;
  grid-gap: ${measure.measure16};
  align-items: flex-start;
`

const Text = styled.div<Pick<Props, "variant">>`
  color: ${({ variant }) => theme[variant].text};
  font-size: ${fontSizes.size16};
  font-weight: 400;
  text-align: left;
`

export const Alert = ({ className, children, variant }: Props) => (
  <Root className={className} variant={variant}>
    {theme[variant].icon}
    <Text variant={variant}>{children}</Text>
  </Root>
)
