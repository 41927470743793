import React from "react"
import styled from "styled-components"
import { Button } from "../components/Button"
import { measure } from "../styles"
import { API_URL } from "gatsby-env-variables"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import { useMutation } from "react-query"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Language } from "../types"
import { useLanguage } from "../utils/useLanguage"
import { Alert } from "../components/Alert"
import {
  Field,
  Label,
  FieldErrorMessage,
  Input,
  TextArea,
} from "../components/form"

const i18n = {
  es: {
    labels: {
      name: "Tu nombre",
      email: "Tu email",
      message: "Mensaje",
    },
    errors: {
      mandatory: "Este campo es obligatorio",
      email: "Por favor, introduce un email válido",
      failure:
        "Lo sentimos, ha habido un error al enviar el formulario. Por favor, vuelve a intentarlo más tarde.",
    },
    success:
      "Gracias por tu mensaje! Me pondré en contacto contigo lo antes posible.",
    send: "Enviar",
    sending: "Enviando",
  },
  en: {
    labels: {
      name: "Your name",
      email: "Your email",
      message: "Message",
    },
    errors: {
      mandatory: "This field is mandatory",
      email: "Please use a valid email",
      failure:
        "Apologies, there has been an error sending the form. Please, try again later.",
    },
    success:
      "Thank you for your message! I'll get back to you as soon as possible.",
    send: "Send",
    sending: "Sending",
  },
}

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`

const SpinnerIcon = styled(FontAwesomeIcon).attrs({
  icon: faCircleNotch,
  spin: true,
})`
  margin-right: ${measure.measure8};
`

const StyledErrorAlert = styled(Alert).attrs({ variant: "error" })`
  margin-bottom: ${measure.measure16};
`

type FormData = {
  name: string
  email: string
  message: string
}

const getSchema = (lang: Language) =>
  yup
    .object()
    .shape({
      name: yup.string().trim().required(i18n[lang].errors.mandatory),
      email: yup
        .string()
        .email(i18n[lang].errors.email)
        .trim()
        .required(i18n[lang].errors.mandatory),
      message: yup.string().trim().required(i18n[lang].errors.mandatory),
    })
    .required()

export const ContactForm = () => {
  const lang = useLanguage()

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(getSchema(lang)),
  })

  const {
    isLoading,
    isSuccess,
    error: submitError,
    mutate,
  } = useMutation((data: FormData) =>
    axios.post(`${API_URL}/contact-form`, data)
  )

  const onSubmit = (data: FormData) => mutate(data)

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Label>{i18n[lang].labels.name}</Label>
        <Input
          type="text"
          name="name"
          ref={register}
          invalid={!!errors.name}
          disabled={isLoading || isSuccess}
        />
        {errors.name && (
          <FieldErrorMessage>{errors.name.message}</FieldErrorMessage>
        )}
      </Field>
      <Field>
        <Label>{i18n[lang].labels.email}</Label>
        <Input
          type="email"
          name="email"
          ref={register}
          invalid={!!errors.email}
          disabled={isLoading || isSuccess}
        />
        {errors.email && (
          <FieldErrorMessage>{errors.email.message}</FieldErrorMessage>
        )}
      </Field>
      <Field>
        <Label>{i18n[lang].labels.message}</Label>
        <TextArea
          name="message"
          ref={register}
          invalid={!!errors.message}
          disabled={isLoading || isSuccess}
        />
        {errors.message && (
          <FieldErrorMessage>{errors.message.message}</FieldErrorMessage>
        )}
      </Field>
      {isSuccess ? (
        <Alert variant="success">{i18n[lang].success}</Alert>
      ) : (
        <>
          {submitError && (
            <StyledErrorAlert>{i18n[lang].errors.failure}</StyledErrorAlert>
          )}
          <Button type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <SpinnerIcon />
                {i18n[lang].sending}
              </>
            ) : (
              i18n[lang].send
            )}
          </Button>
        </>
      )}
    </Form>
  )
}
