import styled, { css } from "styled-components"
import { colors, fontSizes, measure } from "../styles"

const inputStyle = css`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 1px solid ${colors.gray200};
  border-radius: 5px;
  padding: 0 ${measure.measure8};
  color: ${colors.grey800};

  &:focus {
    border-color: ${colors.gray400};
  }
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${measure.measure16};
`

export const Label = styled.label`
  color: ${colors.grey700};
  margin-bottom: ${measure.measure4};
`

export const Input = styled.input<{ invalid: boolean }>`
  ${inputStyle}
  height: 48px;

  &:disabled {
    color: ${colors.grey700};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: red;
    `}
`

export const TextArea = styled.textarea<{ invalid: boolean }>`
  ${inputStyle}
  max-width: 512px;
  height: ${measure.measure128};
  padding: ${measure.measure8};

  &:disabled {
    color: ${colors.grey700};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: red;
    `}
`

export const FieldErrorMessage = styled.span`
  font-size: ${fontSizes.size16};
  color: red;
  margin-top: ${measure.measure4};
`
