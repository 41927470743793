import React from "react"
import styled, { css } from "styled-components"
import Image, { GatsbyImageFluidProps } from "gatsby-image"
import { media, measure, breakpoints } from "../styles"
import { Fade } from "react-awesome-reveal"

export interface Props {
  imageFluid: GatsbyImageFluidProps["fluid"]
  imageHeightRatio: number
  title?: string
  content: React.ReactNode
  reverseOrder?: boolean
}

const Root = styled.div<Pick<Props, "reverseOrder">>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${measure.measure72};
  align-items: center;

  ${media.biggerThan(breakpoints.large)} {
    ${({ reverseOrder }) =>
      reverseOrder &&
      css`
        direction: rtl;
      `}
  }

  ${media.smallerThan(breakpoints.large)} {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${measure.measure48};
    }
  }

  ${media.smallerThan(breakpoints.medium)} {
    & > * + * {
      margin-top: ${measure.measure32};
    }
  }
`

const TitleSmallScreen = styled.h1`
  margin-left: ${measure.measure16};
  margin-right: ${measure.measure16};
  text-align: center;
  margin-bottom: 0;

  ${media.biggerThan(breakpoints.large)} {
    display: none;
  }
`

const TitleLargeScreen = styled.h1`
  margin-bottom: ${measure.measure24};
  ${media.smallerThan(breakpoints.large)} {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  direction: ltr;
  min-width: 100%;
  width: 100%;

  ${media.biggerThan(breakpoints.large)} {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 24px;
  }

  ${media.smallerThan(breakpoints.medium)} {
    padding-left: ${measure.measure16};
    padding-right: ${measure.measure16};
  }
`

const StyledImage = styled(Image).attrs({
  imgStyle: {
    objectFit: "cover",
  },
})<GatsbyImageFluidProps & { imageHeightRatio: number }>`
  padding-top: ${({ imageHeightRatio }) => `${imageHeightRatio}%`};
  height: 0;
  width: 100%;

  ${media.smallerThan(breakpoints.medium)} {
    padding-top: 75%;
  }
`

const StyledFade = styled(Fade)`
  width: 100%;
`

export const Diptych = ({
  imageFluid,
  imageHeightRatio,
  title,
  content,
  reverseOrder,
}: Props) => (
  <StyledFade>
    <Root reverseOrder={reverseOrder}>
      {title && <TitleSmallScreen>{title}</TitleSmallScreen>}
      <StyledImage fluid={imageFluid} imageHeightRatio={imageHeightRatio} />
      <Content>
        {title && <TitleLargeScreen>{title}</TitleLargeScreen>}
        {content}
      </Content>
    </Root>
  </StyledFade>
)
